.company-cards-main{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10vh;
    transition: all ease-in-out .2s;
}

.company-section{
    width: 100vw;
    background-color: var(--bg-color);
    transition: all ease-in-out .2s;
    position: relative;
}

.company-section h1{
    color: var(--intense-violet);
    /* border-bottom: 1rem solid var(--golden-yellow); */
    text-align: left;
    padding: 4rem 0;
    text-transform: uppercase;
}

.company-card{
    border-radius: 2rem;
}

.company-card h2{
    text-align: right;
    text-transform: uppercase;
    opacity: 0.7;
    font-style: oblique;
    color: var(--emerald-green);
}

/* .company-card:first-child{
} */

#company-icons-selector{
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;
    min-height: 10vh;
    border-bottom: 0.4rem solid var(--emerald-green);
    padding-top: 3rem;
}

#company-icons-selector i{
    font-size: 15rem;
}

.company-icon:hover{
    color: var(--intense-violet);
}


.company-icon{
    opacity: 0.5;
    /* color: var(--bg-color-i); */
    color: var(--emerald-green);
    transition: all ease-in-out .2s;
    width: 100%;
    text-align: center;
    cursor: pointer;
}

.company-icon.active{
    opacity: 1;
    color: var(--intense-violet);
    border-bottom: 0.8rem solid var(--intense-violet) !important;
}

.company-parragraph{
    padding: 4rem 0;
    font-size: 5rem;
    line-height: 6rem;
}