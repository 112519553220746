
.contact-section{
    background-color: var(--deep-blue);
}

.contact-section h2{
    color: var(--bg-color);
}
.contact-section p{
    color: var(--bg-color);
    margin: 3vh 0;
}

.contact-section button{
    background-color: var(--intense-violet);
    width: 100%;
    margin-bottom: 5vh;
}
.contact-section button:hover{
    background-color: var(--bg-color);
    color: var(--intense-violet);
}

.contact-section .text{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap:4rem;
    text-align: center;
}

