.hero-section{
    display:  flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
    gap:6rem;
    position: relative;
    overflow-x: hidden;
    padding-top: 6vh;
}

.hero-section h1{
    text-transform: uppercase;
}

.hero-section .text,
.hero-button{
    width: 50%;
}

.hero-section .text{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 8rem;
    /* padding-left: 3rem; */
}

.hero-logo{
    position: absolute;
    right: 0;
    top: 30%;
    margin: auto;
    height: 70%;
    transform: translateX(var(--wolf-hero-translate)); /*Created in ThemeContext.jsx*/
}
