.contact-page{
    min-height: 90vh;
    background-color: var(--bg-color);
    transition: all ease-in-out .2s;
}

.pt-5{
    padding-top: 5vh;
}

.contact-form{
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    font-size: 5rem;
    gap:3vh;
    /* padding: 10vh; */
    padding: 5vw;
}

.form-control{
    width: 90vw;
}

.form-error{
    color: var(--intense-violet);
    font-weight: 600;
    padding: 0 4rem 2rem 0;
    justify-content: flex-end
}

label{
    color: var(--bg-color-i);
    font-weight: 700;
    opacity: 0.6;
}

select{
    opacity: 0.6 ;
}

/* select[value] {
    opacity: 1;
} */

input, select, textarea{
    width: 100%;
    font-size: 5rem;
    padding: 2rem 2rem;
    color: var(--bg-color-i);
    background-color: var(--bg-color);
    font-weight: 800;

    border: none;
    border-bottom: 0.5rem solid var(--intense-violet);

    margin-top: 2rem;
}


input[aria-invalid='true'], select[aria-invalid='true'], textarea[aria-invalid='true']{
    border: 0.2rem solid var(--intense-violet);
    border-bottom: 0.5rem solid var(--intense-violet);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--bg-color);
  -webkit-box-shadow: 0 0 0px 40rem var(--deep-blue) inset;
}
*:focus {
    outline: none;
}

#comment{
    height: 35vh;
}

#submit-button{
    background-color: var(--intense-violet);
}

#submit-button:hover{
    background-color: var(--deep-blue);
}