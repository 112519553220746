.personal-section{
    overflow: hidden;
    border-bottom: 2rem solid var(--golden-yellow);
}


#personal-image{
    position: absolute;
    right:-18vw;
    bottom: 0;
    margin: auto;
    height: 90%;
    z-index: 1;
}


.personal-section .text{
    width:55%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 5rem;
    flex-direction: column;
}

.personal-button{
    left: 0;
    width: 100%;
    z-index: 2;
}