/*:root {
  --deep-blue: #569cd6;
  --silver-gray: #d4d4d4;
  --golden-yellow: #ce9178;
  --emerald-green: #dcdcaa;
  --intense-violet: #c586c0;
  --bg-color: #1e1e1e;
}

.dark-mode {
  --deep-blue: #356492;
  --silver-gray: #4d4d4d;
  --golden-yellow: #8e6238;
  --emerald-green: #6d846d;
  --intense-violet: #7a4c7a;
  --bg-color: #d4d4d4;
}
*/

*{
  -webkit-transition: inherit;
  transition: inherit;
  box-sizing: border-box;
  margin: 0;
  color: #dddddd;
}

html{
  font-size: 12px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

section{
  padding: 4vh 5vw;
  transition: all ease-in-out .2s;
  background-color: var(--bg-color);
  /* border-bottom: 2rem dashed var(--emerald-green); */
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  font-size: 8rem;
  color: var(--deep-blue);
}

h2 {
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  font-size: 5rem;
  color: var(--intense-violet);
}

h3 {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 4.5rem;
  color: var(--emerald-green);
}

p {
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  font-size: 4rem;
  color: var(--silver-gray);
  line-height: 5rem;
}

a {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 2.6rem;
  color: var(--emerald-green);
  transition: color 0.3s ease;
  text-decoration: none;
}

a:hover {
  color: var(--golden-yellow);
}


span {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 2.6rem;
  color: var(--intense-violet);
}

button {
  display: inline-block;
  padding: 2rem 4rem;
  font-size: 5rem;
  background-color: var(--golden-yellow);
  color: var(--bg-color);
  border: none;
  border-radius: 2rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: 700;
}

button:hover {
  background-color: var(--golden-yellow-i);
  color: var(--bg-color-i)
}

.hided{
  visibility: hidden;
}

path:hover,
polygon:hover{
  filter: brightness(1.5)
}

li{
  list-style-type: none;
  margin-top: 2rem;
}

@import url('./styles/header.css');
@import url('./styles/hero.css');
@import url('./styles/company.css');
@import url('./styles/separator.css');
@import url('./styles/personal.css');
@import url('./styles/certifications.css');
@import url('./styles/contact.css');
@import url('./styles/footer.css');
@import url('./styles/contactPage.css');
@import url('./styles/alert.css');

@import url('./styles/responsive.css');

@keyframes rotate {
  0%{
    transform: rotate(0deg);
  }

  100%{
    transform: rotate(180deg);
  }
}


