@media (min-width: 50px) and (max-width: 523px) {
    html {
        font-size: 3px !important;
    }

    #personal-image {
        right: -4vw;
    }
}

@media (min-width: 524px) and (max-width: 1400px) {

    /*1439px*/
    html {
        font-size: 4px !important;
    }

    .hero-section {
        min-height: 50vh;
    }

    .company-cards-main {
        gap: 10vh;
    }

    .personal-section {
        min-height: 50vh;
    }

    .personal-section .text {
        width: 35%;
    }

    #personal-image {
        right: 0;
    }



    .certifications-cards-main {
        grid-template-columns: repeat(auto-fit, 6vw);
        gap: 10vw;
    }

}

@media (min-width: 1441px) and (max-width: 1800px) {
    html {
        font-size: 6px !important;
    }

    .hero-section {
        min-height: 82vh;
    }

    section {
        padding: 8vh 5vw !important;
    }

    .separator {
        min-height: auto;
    }

    .personal-section .text {
        width: 35%;
    }


    #personal-image {
        right: 5vw;
    }

    .certifications-cards-main {
        grid-template-columns: repeat(auto-fit, 15vw);
        gap: 3vw;
        padding-bottom: 16vh;
    }

    .certification-card .card-image {
        width: 6vw;
        height: 6vw;
    }

    #footer {
        height: 12vh;
    }

}

@media (min-width: 1801px) and (max-width: 2200px) {
    html {
        font-size: 15px !important;
    }

    .personal-section .text {
        width: 45%;
    }

    #personal-image {
        right: 2vw;
    }

    .certifications-cards-main {
        grid-template-columns: repeat(auto-fit, 4vw);
        gap: 10vw;
    }
}

@media (min-width: 2201px) and (max-width: 2800px) {
    html {
        font-size: 16px !important;
    }


    .hero-section {
        min-height: 65vh;
    }

    .personal-section .text {
        width: 45%;
    }

    #personal-image {
        right: -2vw;
    }

    .certifications-cards-main {
        grid-template-columns: repeat(auto-fit, 4vw);
        gap: 10vw;
    }
}

@media (min-width: 2801px) and (max-width: 4000px) {
    html {
        font-size: 17px !important;
    }


    .hero-section {
        min-height: 65vh;
    }

    .personal-section .text {
        width: 40%;
    }

    #personal-image {
        right: 0;
    }

    .certifications-cards-main {
        grid-template-columns: repeat(auto-fit, 4vw);
        gap: 10vw;
    }
}

@media (min-width: 4001px) {
    html {
        font-size: 16px !important;
    }


    .hero-section {
        min-height: 60vh;
    }

    .personal-section .text {
        width: 35%;
    }

    #personal-image {
        right: 5vw;
    }

    .certifications-cards-main {
        grid-template-columns: repeat(auto-fit, 0.5vw);
        gap: 12vw;
    }
}