#alert-wrapper{
    position: fixed;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 12;
    background-color: #32323299;
    display: none;
}

#alert-modal{
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 80vw;
    height: fit-content;
    padding: 3rem;
    border-radius: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5vh;
    padding: 5rem;
    color: var(--bg-color);
    font-weight: 600;
    background-color: var(--bg-color);
}

#alert-modal .title{
    font-size: 7rem;
    text-transform: uppercase;
}
#alert-modal .message{
    font-size: 4rem;
    color: var(--bg-color-i);
    text-align: center;
}

#alert-modal .closing{
    font-size: 4rem;
    color: var(--bg-color-i);
    font-weight: 200;
    cursor: pointer;
}

#alert-modal.ok .title{
    color: var(--emerald-green);
}
#alert-modal.error .title{
    color: var(--intense-violet);
}


#alert-wrapper.active{
    display: block;
}

