#header {
    display: flex;
    align-items: stretch;
    justify-content: flex-end;
    height: 2rem;
    gap: 4rem;
    /* padding: 1rem; */

    color: var(--bg-color);
    position: fixed;
    top: 2vh;
    left: 5vw;
    right: 5vw;
    margin: auto;

    width: 90vw;
    z-index: 10;

}

#header .config {
    display: flex;
    justify-content: center;
    /* align-items: start; */
    align-items: center;
    align-self: flex-start;
    flex-direction: row;
    gap: 5rem;
    border-radius: 1rem;
    background-color: var(--deep-blue);
}

.header-link {
    font-size: 5rem;
    font-weight: 700;
    color: var(--bg-color);
    text-transform: uppercase;
    text-decoration: none;
}


.header-blank {
    height: 3rem;
    background-color: var(--bg-color);
    transition: all ease-in-out .2s;
}

.header-icon-wraper {
    height: fit-content;
}

#header i,
#header svg {
    color: var(--bg-color);
    font-size: 7rem;
    height: 100%;
    cursor: pointer;
    padding: 1rem;

    border-radius: 1rem;
    background-color: var(--deep-blue);
}



.header-logo {
    height: 80%;
    cursor: pointer;
}

.header-flag {
    color: var(--bg-color);
    font-weight: 700;
    font-size: 3rem;
    cursor: pointer;
}

.language-select-main {
    align-self: flex-start;
    height: 100%;
}

.header-link:hover,
#header i:hover,
.header-flag:hover {
    color: var(--bg-color-i);
}


.language-select {
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    height: 100%;
    padding: 0 2rem;
    cursor: pointer;
}

.language-select .rotate {
    animation: rotate .2s;
    transform: rotate(180deg);
}

.lang-drop-down {
    background-color: var(--deep-blue);
    text-align: center;
    transition: max-height 0.15s ease-out;
    max-height: 0px;
    overflow: hidden;
    border-radius: 0 0 1rem 1rem;
}

.lang-drop-down.dropped {
    max-height: 2000px;
}

.drop-down-item {
    border-top: 1px solid var(--emerald-green);
    font-size: 4rem;
    padding: 1rem;
    margin: 0;
    color: var(--bg-color);
    text-transform: uppercase;
    cursor: pointer;
    transition: all ease-in-out .2s;
}

/* .drop-down-item:hover {
    color: var(--bg-color-i);
    background-color: var(--deep-blue-i);
} */
.drop-down-item:hover,
.drop-down-item.selected {
    background-color: var(--bg-color);
    color: var(--deep-blue);
}