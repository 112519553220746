.certifications-section{
    position: relative;
    padding: 1vw 5vw;
}

.certifications-header{
    border-radius: 2rem 2rem 0 0;
    background-color: var(--deep-blue);
    color: var(--bg-color);
    font-size: 6rem;
    padding: 2rem;
    text-align: center;
}

.pdf-viewer{
    width: fit-content;
    overflow: hidden;
    border: 1rem solid  var(--deep-blue);
}

.pdf-canvas canvas{
    width: 100% !important;
    height: auto !important;
}

#certifications-button{
    /* position: absolute; */
    /* bottom: 10rem; */
    left: 1rem;
    right: 1rem;
    bottom: 0;
    margin: auto;
    text-decoration: none;

    display: flex;
    align-items: center;
    justify-content: center;
}

#certifications-button button{
    font-size: 4rem;
    width: 100%;
    border-radius: 0 0 2rem 2rem;
    background-color: var(--deep-blue);
    color: var(--bg-color);
}


/* cards */
.certifications-cards-main{
    display: grid;
    grid-template-columns: repeat( auto-fit, 18vw );
    gap: 2vh;
    justify-items: center;
    justify-content: center;
    position: relative;
    padding: 10vh 2vw;
    margin: 10vh 0;
}

.certification-card{
    display: flex;
    align-items: stretch;
    justify-content: space-evenly;
    flex-direction: column;
    text-transform: uppercase;
    text-align: center;
}


.certification-card .card-image{
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    /* max-height: 90%; */
    width: 10vw;
    height: 10vw;

    filter:grayscale(1);
    transition: all ease-in-out 0.2s;
    border-radius: 2rem;
}

.certification-card .card-image:hover{
    filter:grayscale(0);
}

.certifications-text{
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    margin: 0;
    text-align: center;
    padding: 4rem;
}

.certifications-text.title{
    top:0;
}
/* .certification-card:hover .card-text{
    opacity: 1;
}

.certification-card .card-text{
    padding: 2rem;
    opacity: 0;
    font-size: 4rem;
    font-weight: 700;
} */


