.separator-text{
    color: var(--deep-blue);
    font-size: 10rem;
    text-transform: uppercase;
    width: 50%;
    border-top: 2rem dashed var(--emerald-green);
    border-bottom: 2rem dashed var(--emerald-green);
    text-align: left;
    padding: 1rem;
}

.separator{
    display: flex;
}

.separator.right{
    justify-content: flex-end;
}

.separator.right .separator-text{
    text-align: right;
}

.separator.center{
    justify-content: center;
}
.separator.center .separator-text{
    text-align: center;
    width: 100%;
}

.separator{
    padding: 3vh 5vw;
    /* border-top: 2rem dashed var(--emerald-green); */
}