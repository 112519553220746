#footer{
    display: grid;
    /* grid-template-columns: 1fr 2fr; */
    grid-template-areas:
        'logo link link'
        'cpr cpr cpr';
    align-items: center;
    justify-content: space-around;
    justify-items: center;
    height: 8vh;

    background-color: var(--deep-blue);
    color: var(--bg-color);

    width: 100vw;
    z-index: 10;
    border-radius: 0;
    position: relative;
    padding: 2vh 0;
    grid-row-gap: 2vh;
    transition: all ease-in-out .2s;
    border-top: 1px solid var(--bg-color);

}

#footer .links{
    display: flex;
    justify-content: flex-start;
    gap: 20vw;
    align-items: center;
    grid-area: link;
    cursor: pointer;
}



.footer-logo{
    height: 100%;
    transform: translateY(1rem);
    grid-area: logo;
}

.footer-link{
    font-size: 3rem;
    font-weight: 700;
    color: var(--bg-color);
    text-transform: uppercase;
}

.footer-link:hover{
    color:var(--bg-color-i)
}

.copyritgth{
    /* position: absolute; */
    /* bottom: 0;
    right: 0;
    left:0;
    margin: 0;*/
    width: 100vw;
    grid-area: cpr;
    text-align: center;
    font-weight: 700;
    font-size: 3rem;
    color:var(--bg-color-i);
    background-color: var(--bg-color);
}
